<template>
  <div id="App">
    <Logo></Logo>
    <van-row gutter="20">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-form @submit="onSubmit">
          <van-field
            v-model="userInfo.userPhoneNum"
            :name="dh"
            :label="dh"
            :placeholder="dh"
            :rules="[{ required: true, message: '请填写' + dh }]"
          />
          <van-field
            v-model="userInfo.userCardID"
            :name="zh"
            :label="zh"
            :placeholder="zh"
            :rules="[{ required: true, message: '请填写' + zh }]"
          />
          <van-field
            v-model="userPwd"
            :name="mm"
            :label="mm"
            :placeholder="mm"
            type="password"
            :rules="[{ required: true, message: '请填写' + mm }]"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit">
              登录
            </van-button>
          </div>
        </van-form>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
     <footer class="footer">
      <p>太仓市全民健身指导服务中心</p>
      <p>咨询电话: 53517000  投诉电话: 53516059</p>
      <p>ICP备案号: 苏ICP备2024084640号</p>
    </footer>
    <van-overlay :show="show">
      <div class="wrapper">
        <van-loading color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.footer {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #888;
}
</style>

<script>
import { mapState } from "vuex";
import Logo from "../components/Logo.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      userInfo: {
        userName: "",
        userCardID: "",
        userPhoneNum: "",
        loginType: "0",
        token: "",
        wechatUserCode: ""
      },
      userPwd: "",
      show: false,
      dh: "会员电话",
      zh: "会员卡号",
      mm: "密码"
    };
  },
  computed: {
    ...mapState(["weChatAppId", "weChatUrl"])
  },
  created: function() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // 微信浏览器下
      if (typeof this.$route.query.code === "undefined") {
        window.location.href = `${`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          this.weChatAppId
        }&redirect_uri=${encodeURI(
          this.weChatUrl
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`}`;
      } else {
        this.show = true;
        var that = this;
        that.$axios
          .get("/api/WeChat", {
            params: {
              code: that.$route.query.code
            }
          })
          .then(response => {
            if (response.data.userCardID != "") {
              that.userInfo.loginType = response.data.loginType;
              that.userInfo.wechatUserCode = response.data.wechatUserCode;
              that.userInfo.userName = response.data.userName;
              that.userInfo.userCardID = response.data.userCardID;
              that.userInfo.token = response.data.token;
              that.$store.commit("setUserInfo", that.userInfo);
              if(that.userInfo.userCardID == "admin")
              {
                that.$router.push("/scanqr");
              }
              else
              {
                that.$router.push("/selyylist");
              }
            } else {
              that.userInfo.wechatUserCode = response.data.wechatUserCode;
            }
            that.show = false;
          })
          .catch(error => {
            that.show = false;
            Dialog({ message: error });
          });
      }
    }
  },
  components: {
    Logo
  },
  methods: {
    onSubmit() {
      console.log()
      var that = this;
      that.$axios
        .get("/api/Auth", {
          params: {
            cardID: that.userInfo.userCardID,
            pwd: that.userPwd,
            wechatUserCode: that.userInfo.wechatUserCode,
            dh: that.userInfo.userPhoneNum
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.userInfo.userName = response.data.userName;
            that.userInfo.token = response.data.token;
            that.$store.commit("setUserInfo", that.userInfo);
              if(that.userInfo.userCardID == "admin")
              {
                that.$router.push("/scanqr");
              }
              else
              {
                that.$router.push("/selyylist");
              }
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
            Dialog({ message: error });
        });
    }
  }
};
</script>
